import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { LibRoutes, Utils, NO_USER_INFO, NavigationConfig, UserInfo } from "@premium-portal/types";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private readonly config: NavigationConfig;
  protected userInfo: UserInfo = NO_USER_INFO;
  private readonly isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(Utils.NAVIGATION_CONFIG) config: NavigationConfig, protected router: Router) {
    this.config = config;
  }

  public setUserInfo(userInfo: UserInfo): void {
    this.userInfo = userInfo;
    if (this.userInfo !== NO_USER_INFO) {
      this.isAuthenticated$.next(true);
    } else {
      this.isAuthenticated$.next(false);
    }
  }

  public getUserInfo(): UserInfo {
    return this.userInfo;
  }

  public isAuthenticated(): Observable<boolean> {
    return this.isAuthenticated$.asObservable();
  }

  public navigate(route: LibRoutes): void {
    const { environment, shouldUseLocalRouter } = this.config;

    if (shouldUseLocalRouter) {
      this.router.navigate([route]).then();
      return;
    }

    window.location.href = `${environment.url}${route}`;
  }
}
