export interface NotificationDto {
  data: ProcessedNotification[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export interface ProcessedNotification {
  notificationId: string;
  title: string;
  message: string;
  read: boolean;
  created: Date;
  severity: "Alarm" | "Warning" | "Information";
}

export interface NotificationHubResponse {
  notificationId?: string;
}

export interface NotificationWithAction extends NotificationHubResponse {
  action: NotificationAction;
}

export enum NotificationAction {
  Add = "Add",
  Read = "Read",
  ReadAll = "ReadAll",
  Delete = "Delete",
  DeleteAll = "DeleteAll",
}
