import { Inject, Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";
import { NotificationAction, NotificationHubResponse, NotificationWithAction, ProcessedNotification } from "../models/notification.model";
import { SignalRNotificationMethod } from "../enums/signal-r-notification-method.enum";
import { NavigationService } from "../../navigation.service";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Utils, NavigationConfig } from "@premium-portal/types";

@Injectable({ providedIn: "root" })
export class NotificationsSignalRService {
  private static readonly NOTIFICATIONS_REALTIME_HUB = "premium-portal/notifications/real-time";

  protected notifications$ = new Subject<ProcessedNotification>();
  protected notificationWithAction$ = new Subject<NotificationWithAction>();

  private readonly baseUrl: string;
  private hubConnection: HubConnection;

  constructor(@Inject(Utils.NAVIGATION_CONFIG) config: NavigationConfig, menuService: NavigationService) {
    this.baseUrl = config.shouldUseCookieAuthentication ? config.environment.gatewayUrl.toString() : config.environment.apiUrl.toString();
    this.hubConnection = config.shouldUseCookieAuthentication
      ? this.configureHubForCookieAuthentication()
      : this.configureHubForJwtAuthentication(config);

    menuService.isAuthenticated().subscribe((isSignIn: boolean) => (isSignIn ? this.start() : this.stop()));
  }

  /**
   * Configures the SignalR Hub with Cookie authentication
   */
  private configureHubForCookieAuthentication(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(`${this.baseUrl}${NotificationsSignalRService.NOTIFICATIONS_REALTIME_HUB}`, {
        withCredentials: true,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
  }

  /**
   * Configures the SignalR Hub with JWT authentication
   */
  private configureHubForJwtAuthentication(config: NavigationConfig): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(`${this.baseUrl}${NotificationsSignalRService.NOTIFICATIONS_REALTIME_HUB}`, {
        withCredentials: true,
        accessTokenFactory(): string | Promise<string> {
          return config.accessTokenFactory ? config.accessTokenFactory() : "";
        },
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
  }

  public invokeSignalRNotificationMethod<T = unknown>(methodName: SignalRNotificationMethod, argument?: string | string[]): Promise<T> {
    return argument ? this.hubConnection.invoke<T>(methodName, argument) : this.hubConnection.invoke<T>(methodName);
  }

  protected start(): void {
    this.startListeners();
    this.hubConnection.start().then();
    this.hubConnection.onclose((er) => this.onClose(er));
  }

  private startListeners(): void {
    this.hubConnection.on(SignalRNotificationMethod.receive, (notification: ProcessedNotification) => this.onNotify(notification));

    this.hubConnection.on(SignalRNotificationMethod.read, (notification: NotificationHubResponse) =>
      this.onNotifyAction({ notificationId: notification.notificationId, action: NotificationAction.Read })
    );

    this.hubConnection.on(SignalRNotificationMethod.readAll, () => this.onNotifyAction({ action: NotificationAction.ReadAll }));

    this.hubConnection.on(SignalRNotificationMethod.delete, (notification: NotificationHubResponse) =>
      this.onNotifyAction({ notificationId: notification.notificationId, action: NotificationAction.Delete })
    );

    this.hubConnection.on(SignalRNotificationMethod.deleteAll, () => this.onNotifyAction({ action: NotificationAction.DeleteAll }));
  }

  protected onNotify(notification: ProcessedNotification): void {
    this.notifications$.next(notification);
  }

  protected onNotifyAction(notificationAction: NotificationWithAction): void {
    this.notificationWithAction$.next(notificationAction);
  }

  public getMessage(): Observable<ProcessedNotification> {
    return this.notifications$.asObservable();
  }

  public getActionMessage(): Observable<NotificationWithAction> {
    return this.notificationWithAction$.asObservable();
  }

  protected stop(): void {
    this.hubConnection?.stop().then();
  }

  protected onClose(error?: Error): void {
    if (error) {
      this.onError(error);
    }
  }

  protected onError(error: Error): void {
    if (error) {
      console.error(error);
    }
  }
}
